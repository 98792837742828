 <div id="bgimg" class="bgimg">
<br>
   <div class="bgimg2" *ngIf="!isMobileResolution">
     <p align="justify"><span style="font-family: 'Open Sans', sans-serif; line-height: 150%; font-size: 18pt;">
       Die folgende interaktive Karte gibt eine Übersicht über alle Markierer sowie alle Markierungsprojekte, die in der  Fish-Trek-Datenbank enthalten sind. Für weitere Informationen klicken Sie bitte auf das jeweilige Symbol in der Karte.
</span></p>
   </div>
   <div class="bgimg2" *ngIf="isMobileResolution">
     <p align="justify"><span style="font-family: 'Open Sans', sans-serif; line-height: 150%; font-size: 16pt;">
       Die folgende interaktive Karte gibt eine Übersicht über alle Markierer sowie alle Markierungsprojekte, die in der  Fish-Trek-Datenbank enthalten sind. Für weitere Informationen klicken Sie bitte auf das jeweilige Symbol in der Karte.
     </span></p>
       <br>
     </div>
   <div  *ngIf="screenOrientation.type===screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY"><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br></div>

   <div id="map-frame" class="map-frame">
        <div id="map"></div>
      </div>
</div>
