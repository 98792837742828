import {formatDate, Location} from '@angular/common';
import {AfterViewInit, Component, Directive, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import * as L from 'leaflet';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet-extra-markers/dist/js/leaflet.extra-markers.js';
import 'TileLayer.Grayscale.js';
import 'leaflet.legend.js';
import 'leaflet.legend.css';
import {Observable} from 'rxjs';
import {debounceTime, finalize, switchMap, tap, map, startWith} from 'rxjs/operators';
import {Gefunden} from './_models/gefunden';
import { DbService } from './_services/db.service';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import {Fisch} from './_models/fisch';
import {Markierer} from './_models/markierer';
import {Projekt} from './_models/projekt';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // tslint:disable-next-line:object-literal-sort-keys
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
    private service: DbService,
    public screenOrientation: ScreenOrientation) { }
  senderControl = new FormControl();
  public isLoading = false;

  public gefunden: Gefunden[];
  public fisch: Fisch;
  public filteredCode: Fisch[];
  public markierer: Markierer[];
  public projekte: Projekt[];
  public map;
  public layers;
  public layerprojekte;
  public layermarker;
  public layerfische;
  public fishIcon;
  public fish2Icon;
  public fish3Icon;
  public markIcon;
  public projectIcon;
  public fish;
  public code: boolean;
  public details: string;
  public details2: string;
  public details3: string;
  public isMobileResolution = false;

  // tslint:disable-next-line:typedef
  public ngOnInit() {

    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
      document.getElementById('bgimg').style.width = '100%';
      document.getElementById('bgimg').style.height = '100%';
      document.getElementById('bgimg').style.left = '0%';
      this.changeOrientation();
      this.screenOrientation.onChange().subscribe(
        () => {
          this.changeOrientation();
        }
      );
    } else {
      this.isMobileResolution = false;
    }

    this.service.getAll('markierers')
    .subscribe(markierer => this.markierer = markierer);
    this.service.getAll('gefunden')
      .subscribe(gefunden => this.gefunden = gefunden);
    this.service.getAll('projekte')
      .subscribe(projekte => this.projekte = projekte);

    this.fisch = this.fish = null;
    this.details = 'Bitte klicken Sie einen Marker an, um weitere Informationen zu erhalten';

    this.fishIcon = L.icon({
      iconUrl: 'FishTrek.png',
      shadowUrl: 'schatten.png',

      iconSize:     [50, 50], // size of the icon
      shadowSize:   [2, 2], // size of the shadow
      iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
      shadowAnchor: [2, 31],  // the same for the shadow
      popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.markIcon = L.ExtraMarkers.icon({
      icon: 'fa-home',
      markerColor: 'white',
      shape: 'circle',
      prefix: 'fa',
      iconColor: '#6600FF',
      svgOpacity: 0.5,
      svg: true
    });

    this.fish2Icon = L.ExtraMarkers.icon({
      icon: 'fa-fish',
      markerColor: 'white',
      shape: 'circle',
      prefix: 'fa',
      iconColor: '#FF0000',
      svgOpacity: 0.5,
      svg: true
    });
    this.fish3Icon = L.ExtraMarkers.icon({
      icon: 'fa-fish',
      markerColor: 'white',
      shape: 'circle',
      prefix: 'fa',
      iconColor: '#FF0000',
      svgOpacity: 0.5,
      svg: true
    });

    this.projectIcon = L.ExtraMarkers.icon({
      icon: 'fa-flag',
      markerColor: 'white',
      shape: 'circle',
      prefix: 'fa',
      iconColor: '#4eb3d3',
      svgOpacity: 0.5,
      svg: true
    });
/*
    if (new URLSearchParams(window.location.search).get('sender')){
      // tslint:disable-next-line:radix
      this.service.getConcrete('sender', parseInt(new URLSearchParams(window.location.search).get('sender')))
        .subscribe(sender => {
          this.sender = sender;
          this.gefunden.sid = sender.id;
        });
    }else{
      this.sender = {id: 0, name: '' };
    }
*/
    const tiles = L.tileLayer.grayscale('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 4,
      maxZoom: 19,
      // tslint:disable-next-line:object-literal-sort-keys
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    const tiles2 = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 5,
      maxZoom: 19,
      // tslint:disable-next-line:object-literal-sort-keys
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    const baseMaps = {
      Grayscale: tiles,
      colored: tiles2
    };

    this.layerprojekte = L.geoJson();
    this.layerfische = L.geoJson();
    this.layermarker = L.geoJson();

    this.service.getAll('markierers').subscribe( markieres => markieres.forEach(
    marker => {
      this.layermarker.addLayer(L.marker([marker.lat, marker.lon], {icon: this.markIcon}).bindPopup(
        '<strong>Markierer:</strong><br>' + marker.name));
    }));

/*
    this.service.getAll('gefunden').subscribe( markieres => markieres.forEach(
      marker => {
        this.layerfische.addLayer(L.marker([marker.lat, marker.lon], {icon: this.fish2Icon}).on('click', () => {
          this.details = '<strong>Fischart:</strong><br>' + marker.fisch
            + '<br><br><strong>Sendertyp:</strong><br>' + marker.sender;
          this.details2 = '<strong>Gewässer:</strong><br>' + marker.fluss
            + '<br><br><strong>Datum:</strong><br>' + marker.datum;
          this.details3 = '<strong>Länge:</strong><br>' + marker.laenge + ' '
            + 'cm<br><br><strong>Gewicht:</strong><br>' + marker.gewicht + ' g';
        }));
      }
    ));
*/
    this.service.getAll('projekte').subscribe( markieres => markieres.forEach(
      marker => {
        this.layerprojekte.addLayer(L.marker([marker.lat, marker.lon], {icon: this.projectIcon}).bindPopup(
          '<strong>Projektename:</strong><br>' + marker.name + '<br><br>'
          + '<strong>Markierer:</strong><br>' + marker.markierer));
        }));
/*
    const overlayMaps = {
      Markierer: this.layermarker,
      Rueckmeldungen: this.layerfische,
      Projekte: this.layerprojekte
    };
*/
    this.map = L.map('map', {
      center: [ 51, 12 ],
      zoom: 5,
      tap: false,
      // layers: [tiles]
    });

    tiles2.addTo(this.map);
    // L.control.layers(baseMaps).addTo(this.map);

    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      showMarker: false, // optional: true|false  - default true
      showPopup: false,
    });

    this.map.addControl(searchControl);

    this.layermarker.addTo(this.map);
    this.layerprojekte.addTo(this.map);

    L.control.Legend({
      position: 'bottomleft',
      title: 'Legende',
      legends: [{
        label: 'Markierer',
        type: 'image',
        url: 'hausmarker.png',
        layers: [this.layermarker],
        inactive: false,
      },
        {
          label: 'Projekte',
          type: 'image',
          url: 'projectmarker.png',
          layers: [this.layerprojekte],
          inactive: false,
        }]
    }).addTo(this.map);



/*
    this.map.on('click', <LeafletMouseEvent>(e) => {
      this.layers.clearLayers();
      this.layers.addLayer(L.marker([e.latlng.lat, e.latlng.lng], {icon: this.fishIcon}));
    });
    */

    this.senderControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCode = [];
          this.isLoading = true;
        }),
        switchMap(value => this.obsCode(value).pipe(
              finalize(() => {
                this.isLoading = false;
              }),
            )
        )
      )
      .subscribe(
        data => this.filteredCode = data
      );
  }

  obsCode(value: string): Observable<Fisch[]> {
      return this.service.getAllFilter('fischbycode', 'code', value);
  }

  // tslint:disable-next-line:typedef
  displayFn(kunde: Fisch) {
    if (kunde) {
      return kunde.code; }
  }

  changeCode(layer: any): void {
    if (!this.map.hasLayer(layer)){
      layer.addTo(this.map);
    } else {
      this.map.removeLayer(layer);
    }
    this.details = 'Bitte klicken Sie einen Marker an, um weitere Informationen zu erhalten';
    this.details2 = this.details3 = '';
  }

  changeFisch(): void {
    if (this.code) {
      this.fish = L.marker([this.fisch.lat, this.fisch.lon], {icon: this.fish3Icon});
      this.details = '<strong>Fischart:</strong><br>' + this.fisch.fisch + '<br><br><strong>Sendertyp:</strong><br>' + this.fisch.sender;
      this.details2 = '<strong>Code:</strong><br>' + this.fisch.code + '<br><br><strong>Datum:</strong><br>' + this.fisch.datum;
      this.details3 = '<strong>Länge:</strong><br>' + this.fisch.laenge + ' ';
      this.details3 += 'cm<br><br><strong>Gewicht:</strong><br>' + this.fisch.gewicht + ' g';
      this.map.addLayer(this.fish.bindPopup(
        '<strong>Fischart:</strong><br>' + this.fisch.fisch
        + '<br><br><strong>Datum:</strong><br>' + this.fisch.datum + '<br><br>'
        + this.details3));
    } else {
      this.map.removeLayer(this.fish);
      this.details = 'Bitte klicken Sie einen Marker an, um weitere Informationen zu erhalten';
      this.details2 = this.details3 = '';
    }
  }

  changeOrientation(): void {
    if (this.screenOrientation.type === this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY) {
      document.getElementById('map-frame').style.height = '80%';
      document.getElementById('map-frame').style.width = '90%';
      document.getElementById('map-frame').style.left = '5%';
      document.getElementById('map-frame').style.top = '20%';
    } else {
      document.getElementById('map-frame').style.height = '70%';
      document.getElementById('map-frame').style.width = '90%';
      document.getElementById('map-frame').style.left = '5%';
      document.getElementById('map-frame').style.top = '30%';
    }
  }

/*  this.service.getConcrete('fischbyid', id)
.subscribe(fisch => this.fisch = fisch);
  */

  ngAfterViewInit(): void {
  }

}
